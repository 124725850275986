import axios from 'axios';
import router from '@/router';
import store from '../../../store';
import { exampleContentTree } from '../../../services/contentNodes';

export default {
	/**
	 * Grabs all client data from the database and populates it into the vuex store for use within the app.
	 *
	 * @param {Object} context Vuex default context object
	 * @param {String} clientSlug Client slug taken from the URL param
	 * @returns the response so we can use 'await' on this action call elsewhere
	 */
	async fetchClientData(context, clientSlug) {
		// console.log('fetching client data');
		let response = await axios
			.get(`/api/clients/findClient/${clientSlug}`)
			.then(async (response) => {
				// Commits the client's data to the store
				await context.commit('setClientData', response.data);
				// console.log('gotClientData');
				return response.data;
			})
			.catch((err) => console.log(err.response));

		return response;
	},

	clearClientData(context) {
		// console.log('clearing client');
		context.commit('setClientData', {});
		// context.dispatch('clearSelectedNode');
		context.dispatch('clearHighlightNode');
	},

	async createClient(context, image) {
		let contentTree = JSON.stringify(exampleContentTree);
		let clientData = JSON.stringify(context.getters.getClientData);

		const formData = new FormData();
		formData.append('clientData', clientData);
		formData.append('contentTree', contentTree);
		if (image) {
			// console.log('appending image');
			formData.append('avatar', image);
		}

		return axios
			.post('/api/clients/createClient', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then(async (response) => {
				await context.dispatch('fetchClientData', response.data.client.slug);
				return response.data;
			})
			.catch(function (err) {
				return err.response.data?.message;
			});
	},

	async updateClient(context, image) {
		let clientData = JSON.stringify(context.getters.getClientData);

		const formData = new FormData();
		formData.append('clientData', clientData);
		if (image) {
			// console.log('appending image');
			formData.append('avatar', image);
		}

		return axios
			.post(`/api/clients/updateClient/${context.getters.getClientSlug}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => {
				// Need to set the new client data here
				context.commit('setClientData', response.data.client);
				return response.data;
			})
			.catch(function (err) {
				return err.response.data;
			});
	},

	/**
	 * This function can be used to set the 'current node' when a node is selected in the vuex store
	 * Calls the setNode mutation
	 * @param {Object} context Vuex default context object
	 * @param {String} nodeId The ID of the selected NodeLink
	 */
	async selectNode(context, nodeId) {
		// Updating the route query to match the selected node
		await router.replace({ query: { node: nodeId } });

		// Setting node in vuex
		await context.commit('setNode', nodeId);
		return;
	},

	/**
	 * Clears the current selected Node
	 * @param {Object} context Vuex default context object
	 * @param {String} nodeId The ID of the selected NodeLink
	 */
	async clearSelectedNode(context) {
		await context.commit('clearSelectedNode');

		// Removing the route nodeIdquery
		// Because queries can't be mutated directly in vue router we have to use the
		// workaround found here https://stegriff.co.uk/upblog/get-vue-router-to-change-part-of-the-query-string-without-a-page-refresh/
		let route = JSON.parse(JSON.stringify(store.getters.getRoute));
		route.origin = this.userOrigin;
		route.destination = this.userDestination;
		delete route.query.node;
		router.replace(route);
		return;
	},

	/**
	 * This function can be used to set the 'hover highlight' when a node is hovered in the vuex store
	 * Calls the highlightNode mutation
	 * @param {Object} context Vuex default context object
	 * @param {String} nodeId The ID of the selected NodeLink
	 */
	async highlightNode(context, nodeId) {
		await context.commit('highlightNode', nodeId);
		return;
	},

	/**
	 * This function can be used to remove the 'hover highlight' when a node is hovered off in the vuex store
	 * Calls the clearHighlightNode mutation
	 * @param {Object} context Vuex default context object
	 * @param {String} nodeId The ID of the selected NodeLink
	 */
	async clearHighlightNode(context) {
		await context.commit('clearHighlightNode');
		return;
	},

	/**
	 *
	 * @param {Object} context vuex context object
	 * @returns the response so we can use 'await' on this action call elsewhere
	 */
	async saveClientContentTree(context, nodeDeleted) {
		// Allow the tree to perform methods required before save
		// Such as accumulating the reach values
		await context.commit('beforeTreeSave');
		// console.log('afterTreeSave');
		let updatedTree = JSON.stringify(context.getters.getClientContentTree);
		let updatedNode = JSON.stringify(context.getters.getCurrentNode);

		const params = new URLSearchParams();

		params.append('clientSlug', context.getters.getClientSlug);
		params.append('updatedTree', updatedTree);
		params.append('updatedNode', updatedNode);

		let response = await axios
			.post('/api/clients/contentTree/updateContentTree', params, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			})
			.then(async () => {
				console.log('SUCCESS!!', response.data);

				await context.dispatch('fetchClientData', context.getters.getClientSlug);

				// So we just refetched new data, meaning the current node no longer exists
				// Even though it still exists in vuex. We need to clear that node out of Vuex
				// And replace it with the same node from the new fetched data.

				// Save the Id of the node before we clear it
				const oldNodeId = context.getters.getCurrentNode.id;
				// Clear it

				await context.dispatch('clearSelectedNode');

				// Reselect it from the new data
				if (!nodeDeleted) {
					setTimeout(() => {
						context.dispatch('selectNode', oldNodeId);
					}, 150);
				}
			})
			.catch(function (err) {
				console.log('FAILURE!!', err);
			});

		return response;
	},

	// Working on this
	async addNewNode({ commit, state }, { parentNodeId, author }) {
		// console.log(state.clientContentTree);
		//console.log(author, "test in actions")
		commit('addChildNode', { parentNodeId, author });

		// console.log(state.clientContentTree);

		let treeNodes = state.clientContentTree.nodes;
		let newNodeId = treeNodes[treeNodes.length - 1].id;

		// console.log('newNodeId', newNodeId);

		commit('setNode', newNodeId);
	},

	// Working on this
	async removeNode({ commit, getters, dispatch }) {
		const nodeToBeRemoved = getters.getCurrentNode.id;
		await commit('removeNode', nodeToBeRemoved);
		setTimeout(() => {
			dispatch('saveClientContentTree', true);
			dispatch('fetchClientData', getters.getClientSlug);
		}, 300);
		return;
	},

	/**
	 *
	 * @param {Object} filters the filters to be set
	 */
	updateContentTreeFilters({ commit, dispatch }, filters) {
		commit('setFilters', filters);
		dispatch('filterNodes');
	},

	clearContentTreeFilters({ commit, dispatch }) {
		commit('setFilters', {});
		dispatch('filterNodes');
	},

	filterNodes({ getters, commit }) {
		let filteredNodes = getters.getClientContentTree.nodes;
		let filters = getters.getContentTreeFilters;

		for (let filter in filters) {
			filteredNodes = filteredNodes.filter((node) => {
				if (filters[filter] == 'Show All') {
					return node;
				}

				let nodeFieldValue = node.fieldGroups[filter].fields[filter].value ? node.fieldGroups[filter].fields[filter].value.toLowerCase() : undefined;

				// console.log(nodeFieldValue);
				if (filter === 'created') {
					nodeFieldValue = new Date(node.fieldGroups[filter].fields[filter].value).getYear() + 1900;
				}
				return nodeFieldValue == filters[filter].toLowerCase();
			});
		}

		commit('setFilteredNodes', filteredNodes);
	},
};
