import axios from 'axios';
import store from '../../index';
import router from '@/router';
import authorizationMixins from '../../../mixins/authorizationMixins';

export default {
	/**
	 * @param {String} email the user's email
	 * @param {String} password the user's password
	 * @returns an error message if login fails
	 */
	async login(context, { email, password }) {
		const params = new URLSearchParams();
		params.append('email', email);
		params.append('password', password);

		return await axios
			.post('/api/login', params, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			})
			.then((response) => {
				context.commit('setAuthData', response.data.authData);
				context.dispatch('redirectByRole');
			})
			.catch(function (err) {
				return err.response.data.message;
			});
	},
	/**
	 * The logOut action sends a request to the api logout endpoint which will
	 * clear the user's refreshToken in the database and will clear the browser token cookies
	 */
	async logOut(context) {
		await axios
			.get('/api/logout', {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			})
			.then(async (res) => {
				// Clear auth data from vuex
				context.commit('clearAuthData');
				return res.data.message;
			})
			.catch(function (err) {
				return err.response.data.message;
			});
	},
	/**
	 * Sends a request to the 'verifyToken' endpoint which checks the validity of the user's accessToken
	 * Fires on every route change. Sets the auth data on every request.
	 * @returns true or false depending on whether the request was successful or not.
	 */
	async fetchAuthData(context) {
		let res = axios
			.get('/api/auth/verifyToken')
			.then(async (response) => {
				await context.commit('setAuthData', response.data.authData);
				return true;
			})
			.catch(function () {
				return false;
			});

		return res;
	},
	/**
	 * Redirects the user based on their role & assigned clients
	 * @param {Object} user the user being redirected
	 * @returns
	 */
	redirectByRole(context, user = context.getters.getAuthData) {
		const { can } = authorizationMixins.methods;

		// console.log(store.getters.getRoute);

		if (store.getters.getRoute.query.nextUrl) {
			return router.replace(store.getters.getRoute.query.nextUrl);
		}

		// If the user can access the admin view, send them there
		if (can(user, 'access_global_admin_view')) {
			return router.replace('/admin/dashboard');
		}

		// Else if the user has an assigned client, send them to that client's dashboard
		if (context.getters.getAuthFirstAssignedClient) {
			return router.replace(`/client/${context.getters.getAuthFirstAssignedClient}/dashboard/`);
		}

		// If the user has neither of the above, direct to their user edit page
		return router.replace(`/user/${context.getters.getAuthUsername}/edit/`);
	},
};
