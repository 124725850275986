export default {
	/**
	 * Called by the fetchUserData action on a resolved API response.
	 * Commits the user data to the store.
	 *
	 * @param {Object} state vuex state
	 * @param {Object} data user data received from the API
	 */
	setUserData(state, data) {
		state.userData = data;
	},
	updateUserData(state, { target, value }) {
		// console.log(target, value);
		state.userData[target] = value;
	},
};
