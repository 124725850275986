import viewsGetters from "./getters.views"
import viewsMutations from "./mutations.views"
import viewsActions from "./actions.views"

export default {
	namespaced: true,
	state() {
		return {
			sidebarIsOpen: true,
		}
	},
	mutations: viewsMutations,
	actions: viewsActions,
	getters: viewsGetters,
}
