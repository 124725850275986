export default {
	/**
	 * Realistically, we should avoid using this getter as property names may change e.g.
	 * getClientData.x could become getClientData.y thus requiring an update of all getUserData dot notation references
	 *
	 * It's here just in case!
	 * @returns The entirety of the client's data
	 */
	getClientData: (state) => {
		return state.clientData;
	},
	/**
	 * @returns The client's content tree data (Nodes & Links)
	 */
	getClientContentTree: (state) => {
		return state.clientContentTree;
	},
	/**
	 * @returns The client's content tree data (Nodes & Links)
	 */
	getClientContentTreeReach: (state) => {
		let rootNodeId = state.clientContentTree.rootNodeId;
		let rootNode = state.clientContentTree.nodes.find((node) => node.id === rootNodeId);
		return rootNode.accumulativeReach;
	},
	/**
	 * @returns The client's name from the client data store
	 */
	getClientName: (state) => {
		return state.clientData.clientName;
	},
	/**
	 * @param {Object} state vuex strategy
	 * @returns The client's slug from the client data store
	 */
	getClientSlug: (state) => {
		return state.clientData.slug;
	},
	/**
	 * Returns field values from the current Node top populate the Node edit
	 * and node overview menus. Specific method of usage can be found in AddNodeForm.vue.
	 * Uses string interpolation to select the fieldgroup and the field within.
	 * @param {String} fieldgroup The fieldgroup to be selected
	 * @param {String} field The field to be selected within the fieldgroup
	 * @returns The value of the field
	 */
	getNodeFieldValue: (state) => (fieldGroup, fieldTitle) => {
		return state.currentNode.fieldGroups[fieldGroup].fields[fieldTitle].value;
	},
	/**
	 *
	 * @returns All of the currently selected node's data
	 */
	getCurrentNode: (state) => {
		return state.currentNode;
	},
	getHighlightedNode: (state) => {
		return state.highlightedNode;
	},
	getClientUsers: (state) => {
		// If there isn't data, we send an empty array.
		return state.clientData.Users || [];
	},
	getContentTreeFilters: (state) => {
		return state.contentTreeFilters;
	},
	getClientMainContact: (state) => {
		return state.clientData.mainContact;
	},
	getFilteredNodes: (state) => {
		return state.filteredNodes;
	},
	getClientAvatarUrl: (state) => {
		return state.clientData.avatarUrl;
	},
	hasCurrentNodeBeenEdited: (state) => {
		return state.hasCurrentNodeBeenEdited;
	},
};
