export default {
	setRoleData(state, data) {
		state.roleData = data;
	},
	updateRoleData(state, { target, value }) {
		state.roleData[target] = value;
	},
	clearRoleData(state) {
		state.roleData = {};
	},
};
