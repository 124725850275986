import roleGetters from './getters.roles';
import roleMutations from './mutations.roles';
import roleActions from './actions.roles';

export default {
	namespaced: true,
	state() {
		return {
			roleData: {},
		};
	},
	mutations: roleMutations,
	actions: roleActions,
	getters: roleGetters,
};
