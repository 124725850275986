function uuidv4() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16))
}

class Field {
	constructor(defaultValue, label) {
		this.id = `field_${uuidv4()}`
		this.label = label
		this.value = defaultValue
	}
}

const defaultFields = {
	hideFromUser: new Field(false, "Hide from user"),
}

class FieldGroup {
	constructor(title, fields) {
		this.id = `field_group-${uuidv4()}`
		this.title = title
		this.fields = {...defaultFields, ...fields}
	}
}

class Node {
	constructor(parent, author) {
		//console.log(author, "test in node class")
		this.id = `node_${uuidv4()}`
		this.parent = parent ? parent.id : "root"
		this.childIds = []
		this.childCount = 0
		this.hasChildren = false
		this.name = this.computeName(parent)
		this.level = parent ? parent.level + 1 : 0

		this.accumulativeReach = 0
		this.historicalAccumulativeReach = []

		this.fieldGroups = {
			title: new FieldGroup("Title", {title: new Field("", "Title")}),
			details: new FieldGroup("Details", {details: new Field("", "Details")}),
			assign: new FieldGroup("Assign", {assign: new Field("", "Assign User")}),
			deadline: new FieldGroup("Deadline", {deadline: new Field(new Date(), "Deadline")}),
			keyword: new FieldGroup("Key Word", {keyword: new Field("", "Keyword"), reach: new Field(0, "Reach")}),
			format: new FieldGroup("Format", {format: new Field("", "Format")}),
			intent: new FieldGroup("Intent", {intent: new Field("", "Intent")}),
			research: new FieldGroup("Research", {research: new Field("", "Research")}),
			status: new FieldGroup("Status", {status: new Field("Title created", "Status"), notes: new Field("", "Notes")}),
			author: new FieldGroup("Author", {author: new Field(author, "Author")}),
			created: new FieldGroup("Created", {created: new Field(new Date(), "Created")}),
		}
	}
	computeName(parent) {
		if (parent) {
			return `${parent.name}.${parent.childCount + 1}`
		} else {
			return "1"
		}
	}
}

class NodeLink {
	constructor(sourceId, targetId) {
		this.source = sourceId
		this.target = targetId
	}
}

class ContentTree {
	constructor() {
		this.links = []
		this.nodes = []
		this.nodeIds = []
		this.rootNodeId = ""
		this.addRootNode()
	}

	async beforeSaveTree() {
		await this.accumulateReach()
		return
	}

	cleanTree() {
		this.links = []
		this.nodes = {}
	}

	addRootNode() {
		const newRootNode = new Node()
		this.nodes.push(newRootNode)
		this.rootNodeId = newRootNode.id
		this.nodeIds.push(newRootNode.id)
	}

	addChildNode(parentID, author) {
		let parentNodeArray = this.nodes.filter((node) => node.id === parentID)
		let parentNode = parentNodeArray[0]

		const newNode = new Node(parentNode, author)

		// Add the node to the nodes object so
		this.nodes.push(newNode)

		// Update the parent node
		parentNode.childIds.push(newNode.id)
		parentNode.childCount += 1
		parentNode.hasChildren = true

		// Update the link list
		const newLink = new NodeLink(parentID, newNode.id)
		this.links.push(newLink)

		// Update the nodeId list
		this.nodeIds.push(newNode.id)

		// Allows us to get the id of the new node from this function
		return newNode.id
	}

	/**
	 * This function removes a node from the node tree, will only work if the node has no children
	 * Updates the nodeLink list and the parent node
	 * @param {String} nodeID the ID of the node to be removed
	 */
	removeNode(nodeID) {
		let currentNodeArray = this.nodes.filter((node) => node.id === nodeID),
			currentNode = currentNodeArray[0],
			parentNode = this.nodes.find((node) => node.id === currentNode.parent)

		if (currentNode.hasChildren === false) {
			// Find the index of the node to remove & see if it exists
			const findIndex = this.nodes.findIndex((currentNode) => currentNode.id === nodeID)

			// If the node exists
			if (findIndex > -1) {
				// Remove the node
				this.nodes.splice(findIndex, 1)

				// Update the link list
				this.links = this.links.filter((pair) => pair.target !== nodeID)

				// Update the parent node
				parentNode.childIds = parentNode.childIds.filter((id) => id !== nodeID)
				parentNode.childCount = parentNode.childCount - 1
				if (parentNode.childCount <= 0) {
					parentNode.hasChildren = false
				}
				this.nodeIds = this.nodeIds.filter((id) => id !== nodeID)

				// console.log("parentNode", parentNode)
				// console.log(this.nodes)
				return
			}
		}
	}

	/**
	 * Recursive function that accumulates node reach on each branch
	 * Adds the total reach of all the sub nodes to the level 1 nodes
	 * Also adds the total of all the level 1 nodes to the total of the root node
	 */
	async accumulateReach() {
		// Get all level 1 nodes
		// For each level 1 node, calculate the total reach of all child nodes
		// Add the accumulated total to the level 1 node

		// Find the root node & reset it's accumulativeReach to 0
		const rootNode = this.nodes.find((node) => node.id === this.rootNodeId)
		// archive the rootNode's accumulativeReach
		this.archiveAccumulativeReach(rootNode)
		// Set the rootNode accumulativeReach to 0;
		rootNode.accumulativeReach = 0

		// Find all level one nodes
		const levelOneNodes = this.nodes.filter((node) => node.level === 1)

		// For each level one node
		levelOneNodes.forEach((node) => {
			// Archive the accumulativeReach of the node
			this.archiveAccumulativeReach(node)

			// Recursively add up the reach of all the node's children
			let newAccumulativeReach = this.getTotalChildReachRecursive(node.id)

			// Set the node's accumulativeReach
			node.accumulativeReach = newAccumulativeReach

			// Add the node's accumulativeReach to the overal total reach
			rootNode.accumulativeReach = newAccumulativeReach + rootNode.accumulativeReach
		})

		return
	}

	getTotalChildReachRecursive(nodeId) {
		let theNode = this.nodes.find((node) => node.id === nodeId)

		let reachAccumulator = parseInt(theNode.fieldGroups.keyword.fields.reach.value)

		if (theNode.childIds.length > 0) {
			theNode.childIds.forEach((childNodeId) => {
				let nextChildReach = this.getTotalChildReachRecursive(childNodeId)
				if (!isNaN(nextChildReach)) {
					reachAccumulator += nextChildReach
				}
			})
		}

		return reachAccumulator
	}

	/**
	 * This function takes a node and archive's it's current accumulative reach every month.
	 * @param {Object} node the node we are archiving the accumulative reach of
	 * @returns void
	 */
	archiveAccumulativeReach(node) {
		let currentReach = node?.accumulativeReach || 0
		// If the node has a historicalAccumulativeReach array
		if (node?.historicalAccumulativeReach) {
			// If most recent historicalAccumulator saved less than a month ago don't save new one
			// Get the most recent archive
			const lastArchive = node.historicalAccumulativeReach[node.historicalAccumulativeReach.length - 1]
			// Get the most recent archive's timestamp
			const lastArchiveDate = new Date(lastArchive.timeStamp).getTime()

			/**
			 * DEV NOTE: This is where we change the frequency of reachAccumulation archiving
			 */

			// Calculate the next archive's timestamp (28 days from now);
			// // 10 seconds for testing
			// const daysUntilNextArchive = 0.00011574074;
			const daysUntilNextArchive = 28
			const nextArchiveDate = new Date(lastArchiveDate + 1000 * 60 * 60 * 24 * daysUntilNextArchive)

			// Get the current date
			const dateNow = new Date(Date.now())

			// If today's date is later than the next scheduled archive, archive the accumulative reach
			if (dateNow > nextArchiveDate) {
				node.historicalAccumulativeReach = [...node.historicalAccumulativeReach, {timeStamp: new Date(), reach: currentReach}]
				// console.log("node.historicalAccumulativeReach", node.historicalAccumulativeReach)
			}
		} else {
			// else create the array
			node.historicalAccumulativeReach = [{timeStamp: new Date(), reach: currentReach}]
		}
	}

	// These functions aren't necesarrily needed but prove a point that nodes can be traced back to the root
	getPathFromRoot(nodeId) {
		// console.log(`Getting the path from node: ${nodeId} to the root node`)
		this.recursiveGetParent(nodeId)
	}

	recursiveGetParent(nodeId) {
		let node = this.nodes.find((node) => node.id === nodeId)

		if (node.parent === this.rootNodeId) {
			// console.log(`${nodeId}'s parent is the ROOTNODE!`)
		} else {
			// console.log(`${nodeId}'s parent is ${node.parent}`)
			this.recursiveGetParent(node.parent)
		}
	}
}

const exampleContentTree = new ContentTree()

const exampleNode = new Node()

export {exampleNode, exampleContentTree, ContentTree, Node}

export default Node
