import authGetters from './getters.auth';
import authMutations from './mutations.auth';
import authActions from './actions.auth';

export default {
	namespaced: true,
	state() {
		return {
			authData: {},
			route: '/',
		};
	},
	mutations: authMutations,
	actions: authActions,
	getters: authGetters,
};
