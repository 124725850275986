import { ContentTree, Node } from '../../../services/contentNodes';
export default {
	/**
	 * Called by the fetchClientData action on a positive API response.
	 * Commits the client data to the store.
	 * Creates a blank class instance of a ContentTree and then applies the data received from the api response
	 * to the class instance, allowing us to use the class methods on the tree e.g. addChildNode();
	 *
	 * @param {Object} state vuex state
	 * @param {Object} data client data received from the API
	 */
	setClientData(state, clientData) {
		let blankTreeClassInstance = new ContentTree();

		// Turning the client tree regular JS object back into a class
		// So we can call the class methods such as 'addChild()'.
		let clientTreeClassInstance = Object.assign(blankTreeClassInstance, clientData.contentTree);

		delete clientData.contentTree;

		state.clientData = clientData;

		state.clientContentTree = clientTreeClassInstance;
	},
	/**
	 * Calls the addChildNode class method to add a new child to the content tree under the given parent
	 * @param {Object} state vuex state
	 * @param {String} parentNodeId the id of the parent node that the child is being created under
	 */
	addChildNode(state, { parentNodeId, author }) {
		state.clientContentTree.addChildNode(parentNodeId, author);
		//console.log(author, "test in mutations")
	},
	async removeNode(state, nodeToBeRemovedId) {
		state.currentNode = new Node();
		await state.clientContentTree.removeNode(nodeToBeRemovedId);
		return;
	},
	clearSelectedNode(state) {
		state.currentNode = new Node();
		return;
	},
	async beforeTreeSave(state) {
		await state.clientContentTree.beforeSaveTree();
		return;
	},
	/**
	 * Called by the selectNode action when a node was selected.
	 * Searches the client content Tree for the Node & sets the currentNode as the address of the found node.
	 *
	 * @param {*} state vuex state
	 * @param {String} nodeId ID of the node that was selected
	 */
	setNode(state, nodeId) {
		// Filters all the nodes to select the node with a matching ID
		let currentNodeArray = state.clientContentTree.nodes.find((node) => node.id === nodeId);
		// Gets the object out of the array
		let currentNode = currentNodeArray;
		// Sets the Node Object in the state
		state.currentNode = currentNode;
		// Reset hasCurrentNodeBeenEdited
		state.hasCurrentNodeBeenEdited = false;
		return;
	},
	/**
	 * Called by the selectNode action when a node was selected.
	 * Searches the client content Tree for the Node & sets the currentNode as the address of the found node.
	 *
	 * @param {*} state vuex state
	 * @param {String} nodeId ID of the node that was selected
	 */
	highlightNode(state, nodeId) {
		// Filters all the nodes to select the node with a matching ID
		let currentNodeArray = state.clientContentTree.nodes.filter((node) => node.id === nodeId);
		// Gets the object out of the array
		let currentNode = currentNodeArray[0];
		// Sets the Node Object in the state
		state.highlightedNode = currentNode;
		return;
	},
	/**
	 * Called by the selectNode action when a node was selected.
	 * Searches the client content Tree for the Node & sets the currentNode as the address of the found node.
	 *
	 * @param {*} state vuex state
	 * @param {String} nodeId ID of the node that was selected
	 */
	clearHighlightNode(state) {
		// Sets the Node Object in the state
		state.highlightedNode = new Node();
		return;
	},
	/**
	 * Reactivley updates the currentNode's field value
	 * @param {state} state vuex state
	 * @param {Object} payLoad contains three strings, the fieldGroup, the specific field & the new value {fieldGroup, field, value}
	 */
	updateNodeFieldValue(state, payLoad) {
		state.currentNode.fieldGroups[payLoad.fieldGroup].fields[payLoad.field].value = payLoad.value;
		state.hasCurrentNodeBeenEdited = true;
	},
	setClientName(state, clientName) {
		state.clientData.clientName = clientName;
	},
	setFilters(state, filters) {
		state.contentTreeFilters = filters;
	},
	setFilteredNodes(state, filteredNodes) {
		state.filteredNodes = filteredNodes;
	},
};
