import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import authorizationMixins from '../mixins/authorizationMixins';
const { can, getUserClientRelationship } = authorizationMixins.methods;

const routes = [
	{
		path: '/',
		name: 'root',
		redirect: '/login',
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/Login.vue'),
		meta: {
			noAuthRequired: true,
		},
	},
	{
		path: '/admin',
		name: 'Admin',
		meta: {
			adminOnly: true,
			requiredPermissions: ['access_global_admin_view'],
		},
		component: () => import('../views/Admin.vue'),
		children: [
			{
				path: 'dashboard',
				name: 'Admin Dashboard',
				components: {
					default: () => import('../views/admin/AdminDashboard.vue'),
				},
			},
			{
				path: 'clients',
				name: 'Admin Clients',
				components: {
					default: () => import('../views/admin/Clients.vue'),
				},
			},
			{
				path: 'clients/edit/:client',
				name: 'Edit Clients',
				components: {
					default: () => import('../views/admin/EditClients.vue'),
				},
			},
			{
				path: 'user-management',
				name: 'Admin User Management',
				meta: {
					requiredPermissions: ['read_other_user_data'],
				},
				components: {
					default: () => import('../views/admin/UserManagement.vue'),
				},
			},
			{
				path: 'user-management/:user/edit/',
				name: 'Edit Users',
				components: {
					default: () => import('../views/admin/EditUser.vue'),
				},
			},
			{
				path: 'role-management',
				name: 'Admin Role Management',
				meta: {
					requiredPermissions: ['read_all_role_data'],
				},
				components: {
					default: () => import('../views/admin/RoleManagement.vue'),
				},
			},
			{
				path: 'role-management/:role/edit/',
				name: 'Edit Roles',
				meta: {
					requiredPermissions: ['read_all_role_data'],
				},
				components: {
					default: () => import('../views/admin/EditRole.vue'),
				},
			},
			{
				path: 'settings',
				name: 'Admin Settings',
				components: {
					default: () => import('../views/admin/Settings.vue'),
					//sidebarNav: () => import('../components/sidebar/AdminNav.vue'),
				},
			},
		],
	},
	{
		path: '/user/:user',
		name: 'User',
		component: () => import('../views/User.vue'),
		children: [
			{
				path: 'edit',
				name: 'Edit User',
				components: {
					default: () => import('../views/admin/UserManagement.vue'),
					//sidebarNav: () => import('../components/sidebar/ClientNav.vue'),
				},
			},
		],
	},
	{
		path: '/client/:client',
		name: 'Client',
		component: () => import('../views/Client.vue'),
		children: [
			{
				path: 'dashboard',
				name: 'Client Dashboard',
				props: ['clientSlug'],
				components: {
					default: () => import('../views/client/ClientDashboard.vue'),
				},
			},
			{
				path: 'content',
				name: 'Client Content',
				components: {
					default: () => import('../views/client/ContentTree.vue'),
					nodeOverview: () => import('../components/sidebar/nodeOverview.vue'),
					addNodeForm: () => import('../components/forms/AddNodeForm.vue'),
				},
			},
			{
				path: 'paid-social',
				name: 'Paid Social',
				components: {
					default: () => import('../views/client/ContentTree.vue'),
				},
			},
			{
				path: 'organic-social',
				name: 'Organic Social',
				components: {
					default: () => import('../views/client/ContentTree.vue'),
				},
			},
			{
				path: 'analytics',
				name: 'Analytics',
				components: {
					default: () => import('../views/client/ContentTree.vue'),
				},
			},
			{
				path: 'seo',
				name: 'SEO',
				components: {
					default: () => import('../views/client/ContentTree.vue'),
				},
			},
			{
				path: 'ppc',
				name: 'PPC',
				components: {
					default: () => import('../views/client/ContentTree.vue'),
				},
			},
			{
				path: 'campaign-performance',
				name: 'Campaign Performance',
				components: {
					default: () => import('../views/client/ContentTree.vue'),
				},
			},
			{
				path: 'edit',
				name: 'Edit Client',
				components: {
					default: () => import('../views/admin/EditClients.vue'),
				},
			},
		],
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach(async function (to, from, next) {
	// This is where we add the logic that happens before the user
	// navigates to a new route within the app.
	let noAuthRequired = to.meta.noAuthRequired;
	let isAuthenticated = await store.dispatch('auth/fetchAuthData');
	let authUser = store.getters['auth/getAuthData'];
	let username = store.getters['auth/getAuthUsername'];
	let requiredPermissions = to.meta.requiredPermissions || [];

	// Store the route in Vuex so it can be accessed in vuex methods
	store.commit('setRoute', to);

	// If we're not on the client Content page we want to delete any set filters
	if (to.name !== 'Client Content') {
		// console.log('not client content page');
		await store.dispatch('client/clearContentTreeFilters');
	}

	// If the route requires authentication
	// AND the user is not authenticated
	if (!noAuthRequired && !isAuthenticated) {
		// Redirect the user to the login page
		// console.log('RouterGuard: Redirect the user to the login page');
		return next({
			path: '/login',
			query: {
				nextUrl: to.fullPath,
			},
		});
	}

	// If the user is navigating to the login page
	// AND the user is not authenticated
	let isNavigatingToLogin = to.fullPath === '/login';
	if (isNavigatingToLogin && !isAuthenticated) {
		// Continue to the login page
		// console.log('RouterGuard: Continue to the login page');
		return next();
	} else if (isNavigatingToLogin && isAuthenticated) {
		// If the user is navigating to the login page
		// AND the user IS isAuthenticated
		// redirect the user away from the login page
		// console.log('RouterGuard: Redirect away from login page');
		store.dispatch('auth/redirectByRole');
	}

	// If the route the user is navigating to has a client parameter
	if (to.params.client) {
		// Case 1: User accessing Own client & CAN read own client - pass & fetch data
		if (getUserClientRelationship(authUser, to.params.client) && can(authUser, 'read_own_client_data')) {
			// console.log('RouterGuard: User accessing Own client & CAN read own client - pass & fetch data');
			await store.dispatch('client/fetchClientData', to.params.client);
		}

		// Case 2: User accessing Own client & CANNOT read own client - redirect
		if (getUserClientRelationship(authUser, to.params.client) && !can(authUser, 'read_own_client_data')) {
			// console.log('RouterGuard: User accessing Own client & CANNOT read own client - redirect');
			store.dispatch('auth/redirectByRole');
		}

		// Case 3: User accessing Other client & CAN read other client - pass & fetch data
		if (!getUserClientRelationship(authUser, to.params.client) && can(authUser, 'read_other_client_data')) {
			// console.log('RouterGuard: User accessing Other client & CAN read other client - pass & fetch data');
			await store.dispatch('client/fetchClientData', to.params.client);
		}

		// Case 4: User accessing Other client & CANNOT read other client - redirect
		if (!getUserClientRelationship(authUser, to.params.client) && !can(authUser, 'read_other_client_data')) {
			// console.log('RouterGuard: User accessing Other client & CANNOT read other client - redirect');
			store.dispatch('auth/redirectByRole');
		}
	} else {
		// Clear client data from the vuex store
		// console.log('Clear client data');
		store.dispatch('client/clearClientData');
	}

	// console.log(to.fullPath);
	// If the route the user is navigating to has a user parameter
	if (to.params.user) {
		// Case 1: Own user data
		// Case 2: Someone else's user data
		// Case 3: Someone else's user data in the same client

		// console.log(to.fullPath);
		// If user is trying to access the personal user profile edit
		if (to.fullPath == `/user/${to.params.user}/edit/`) {
			let isOwnProfile = authUser.username === to.params.user;
			let canReadOwnData = can(authUser, 'read_own_user_data');
			let canReadOtherData = can(authUser, 'read_other_user_data');
			let canAccessGlobalAdmin = can(authUser, 'access_global_admin_view');

			// Own profile but can't read own data
			if (isOwnProfile && !canReadOwnData) {
				// Redirect
				store.dispatch('auth/redirectByRole');
			}

			// Own profile and can read own data
			if (isOwnProfile && canReadOwnData) {
				// Continue
				// console.log('Own profile and can read own data');
				next();
			}

			// Other profile & can't read other data & can't access global admin view
			if (!isOwnProfile && !canReadOtherData && !canAccessGlobalAdmin) {
				// Redirect
				next(`/user/${authUser.username}/edit/`);
			}

			// Other profile & CAN read other data & can't access global admin view
			if (!isOwnProfile && canReadOtherData && !canAccessGlobalAdmin) {
				// Redirect
				next(`/user/${authUser.username}/edit/`);
			}

			// Other profile & can read other data & can access admin view
			if (!isOwnProfile && canReadOtherData && canAccessGlobalAdmin) {
				next('/admin/user-management/accountmanager/edit/');
			}
		}

		// User IS accessing OWN user
		if (username === to.params.user) {
			// User CANNOT read own user
			if (!can(authUser, 'read_own_user_data')) {
				store.dispatch('auth/redirectByRole');
			}

			// User CAN read own user
			await store.dispatch('user/fetchUserData', to.params.user);
			return next();
		}

		// User is accessing OTHER user
		// User CANNOT read OTHER user
		if (!can(authUser, 'read_other_user_data')) {
			store.dispatch('auth/redirectByRole');
		}

		// User CAN read OTHER user
		await store.dispatch('user/fetchUserData', to.params.user);
		next();
	} else {
		// Route does NOT contain user param
		// Clear user data from the vuex store
		store.dispatch('user/clearUserData');
	}

	// If the route the user is navigating to has a role parameter
	if (to.params.role) {
		// If the user is not an operator or an account manager
		if (!can(authUser, 'access_global_admin_view')) {
			// Redirect the user back to the dashboard
			store.dispatch('auth/redirectByRole');
		} else {
			await store.dispatch('roles/fetchRoleData', to.params.role);
		}
		// If the route the user is navigating to does not have a role parameter
	} else {
		// Clear role data from the vuex store
		store.dispatch('roles/clearRoleData');
	}

	// Checking route permissions
	if (requiredPermissions.length > 0) {
		for (let permission of requiredPermissions) {
			if (!can(authUser, permission)) {
				store.dispatch('auth/redirectByRole');
			}
		}
		// console.log('you have all the permissions required to access this route');
	}

	next();
});

export default router;
