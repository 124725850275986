import clientGetters from './getters.client';
import clientMutations from './mutations.client';
import clientActions from './actions.client';
import { Node } from '../../../services/contentNodes';

export default {
	namespaced: true,
	state() {
		return {
			clientData: {},
			clientContentTree: {},
			// Initially, we set the current Node to a blank class instance to prevent rendering
			// issues if the api call to fetch client data hasn't resolved yet.
			currentNode: new Node(),
			highlightedNode: new Node(),
			contentTreeFilters: {},
			filteredNodes: [],
			hasCurrentNodeBeenEdited: false,
		};
	},
	mutations: clientMutations,
	actions: clientActions,
	getters: clientGetters,
};
