export default {
	getAuthData(state) {
		return state.authData || '';
	},
	getAuthFirstName(state) {
		return state.authData.firstName;
	},
	getAuthLastName(state) {
		return state.authData.lastName;
	},
	getAuthFullName(state) {
		return state.authData.fullName;
	},
	getRoute(state) {
		return state.route;
	},
	getAuthRoleName(state) {
		return state.authData?.role?.name || '';
	},
	getAuthAvatarUrl(state) {
		return state.authData.avatarUrl || '';
	},
	getAuthFirstAssignedClient(state) {
		let clients = state.authData.assignedClients;
		let assignedClient = Object.keys(clients)[0] || '';
		if (assignedClient.length > 0) {
			// console.log(assignedClient);
			return assignedClient;
		}
		return false;
	},
	getAuthUsername(state) {
		return state.authData.username;
	},
	getAuthPermissions(state) {
		return state.authData.role?.permissions || [];
	},
};
