import axios from "axios"
// import store from '../..';
import router from "@/router"

export default {
	/**
	 * Grabs all user data from the database and populates it into the vuex store for use within the app.
	 *
	 * @param {Object} context Vuex default context object
	 * @param {String} username Username taken from the URL param
	 * @returns the response so we can use 'await' on this action call elsewhere
	 */
	async fetchUserData(context, username) {
		return await axios
			.get(`/api/user/findUser/${username}`)
			.then(async (response) => {
				// Commits the user's data to the store
				context.commit("setUserData", response.data)
			})
			.catch((err) => console.log(err.response))
	},

	clearUserData(context) {
		context.commit("setUserData", {})
	},

	async getAllUsers() {
		const response = await axios
			.get("/api/user/getAllUsers")
			.then((response) => {
				return response.data
			})
			.catch((err) => {
				console.log("FAILURE!!", err.response.data.message)
				this.errorMessage = err.response.data.message
			})

		return response
	},

	async createUser(context, image) {
		let userData = JSON.stringify(context.getters.getUserData)

		//console.log("userData", userData)

		const formData = new FormData()
		formData.append("userData", userData)
		if (image) {
			console.log("appending image")
			formData.append("avatar", image)
		}
		return axios
			.post("/api/user/createUser", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				context.dispatch("clearUserData")
				let username = response.data.username
				return `User ${username} has been added`
			})
			.catch((err) => {
				return err.response.data.message
			})
	},
	async updateUser(context, image) {
		let userData = JSON.stringify(context.getters.getUserData)

		const formData = new FormData()
		formData.append("userData", userData)
		if (image) {
			console.log("appending image")
			formData.append("avatar", image)
		}
		return axios
			.post(`/api/user/updateUser/${context.getters.getUserUsername}`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				// Need to set the new user data here
				context.commit("setUserData", response.data.userData)
				return response.data
			})
			.catch(function (err) {
				//console.log('FAILURE!!', err.response);
				return err.response.data
			})
	},

	async deactivateUser(context, userId) {
		return axios
			.delete(`/api/user/deleteUser/${userId}`)
			.then(() => {
				return router.replace("/admin/user-management")
			})
			.catch(function (err) {
				console.log("FAILURE!!", err.response)
			})
	},

	async logUserOut(context, userId) {
		return axios
			.get(`/api/user/logUserOut/${userId}`)
			.then((response) => {
				return response
			})
			.catch(function (err) {
				console.log("FAILURE!!", err.response)
				return err.response
			})
	},

	// /**
	//  * This function can be used to set the 'current node' when a node is selected in the vuex store
	//  * Calls the setNode mutation
	//  * @param {Object} context Vuex default context object
	//  * @param {String} nodeId The ID of the selected NodeLink
	//  */
	// selectNode(context, nodeId) {
	//   context.commit('setNode', nodeId);
	// },

	// /**
	//  *
	//  * @param {Object} context vuex context object
	//  * @returns the response so we can use 'await' on this action call elsewhere
	//  */
	// async saveClientContentTree(context) {
	//   let updatedTree = JSON.stringify(context.getters.getClientContentTree);

	//   const params = new URLSearchParams();

	//   params.append('userSlug', store.getters['user/getClientSlug']);
	//   params.append('updatedTree', updatedTree);

	//   let response = await axios
	//     .post('/api/users/contentTree/updateContentTree', params, {
	//       headers: {
	//         'Content-Type': 'application/x-www-form-urlencoded',
	//       },
	//     })
	//     .then((response) => {
	//       console.log('SUCCESS!!', response.data);
	//     })
	//     .catch(function (err) {
	//       console.log('FAILURE!!', err);
	//     });

	//   return response;
	// },

	// // Working on this
	// async addNewNode({ commit, state }, parentNodeId) {
	//   console.log(state.clientContentTree);

	//   commit('addChildNode', parentNodeId);

	//   console.log(state.clientContentTree);

	//   let treeNodes = state.clientContentTree.nodes;
	//   let newNodeId = treeNodes[treeNodes.length - 1].id;

	//   console.log('newNodeId', newNodeId);

	//   commit('setNode', newNodeId);
	// },

	// // Working on this
	// async removeNode({ commit, state, getters }) {
	//   console.log(state.clientContentTree);

	//   const nodeToBeRemoved = getters.getCurrentNode.id;

	//   console.log(nodeToBeRemoved);

	//   commit('removeNode', nodeToBeRemoved);

	//   return;

	//   // console.log(state.clientContentTree);

	//   // let treeNodes = state.clientContentTree.nodes;
	//   // let newNodeId = treeNodes[treeNodes.length - 1].id;

	//   // console.log("newNodeId", newNodeId);

	//   // commit("setNode", newNodeId);
	// },
}
