import axios from 'axios';
// import router from '@/router';

export default {
	async createClientUserRelations(context, { client, userIds }) {
		userIds = JSON.stringify(userIds);
		// console.log(client);

		const params = new URLSearchParams();
		params.append('clientId', client.id);
		params.append('userIds', userIds);

		return axios
			.post('/api/relation/createUserClientRelations', params, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			})
			.then((response) => {
				context.dispatch('clearUserData');
				return response.data;
			})
			.catch((err) => {
				return err.response.data;
			});
	},

	async removeClientUserRelations(context, { clientSlug, userIds }) {
		userIds = JSON.stringify(userIds);

		const params = new URLSearchParams();
		params.append('clientSlug', clientSlug);
		params.append('userIds', userIds);

		return axios
			.post('/api/relation/removeUserClientRelations', params, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			})
			// .then((response) => {
			// 	// console.log('SUCCESS!!', response.data.message);
			// })
			.catch((err) => {
				// console.log('FAILURE!!', err.response.data.message);
				this.errorMessage = err.response.data.message;
			});
	},

	async assignUserAsMainContact(context, { client, user }) {
		// console.log(`Assigning ${user.username} as main contact of ${client.clientName}`);

		try {
			if (!user.id) {
				throw 'No user selected.';
			}

			const params = new URLSearchParams();
			params.append('clientId', client.id);
			params.append('userId', user.id);

			return axios
				.post('/api/relation/assignMainContact', params, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				})
				.then((response) => {
					return { message: response.data.message, successful: true };
				})
				.catch((err) => {
					// console.log('FAILURE!!', err.response.data.message);
					return { message: err.response.data.message, successful: false };
				});
		} catch (error) {
			return { message: error, successful: false };
		}
	},
};
