const abilitiesMixin = {
	methods: {
		/**
		 *
		 * @param {Object} user the logged in user
		 * @param {String} permission the permission we are checking
		 * @param {Object} condition the keys refrence data in the user object to check against the values, returns true if values match
		 * @param {Object} noMatchCondition the keys refrence data in the user object to check against the values, returns false if values match
		 * @returns Boolean dependant on whether the user has the permission or the conditions are met
		 */
		can(user, permission, matchCondition, noMatchCondition) {
			// Get the permissions from the user object
			let { permissions: userPermissions } = user.role;

			// If the user can 'do everything' then immediately return true
			if (userPermissions.includes('do_everything')) {
				return true;
			}

			// If the user's permission does not include the permission
			if (!userPermissions.includes(permission)) {
				return false;
			}

			// If there is a MATCH condition
			if (matchCondition) {
				// Get the keys from the condition
				const matchConditionKeys = Object.keys(matchCondition);
				// For each key
				for (let key of matchConditionKeys) {
					// Check that the user[key] matches the condition
					if (user[key] !== matchCondition[key]) {
						// The conditions don't match
						return false;
					} else {
						// The conditions do match
						// Move to the next function call
					}
				}
			}

			// If there is a NOMATCH condition, check the condition
			if (noMatchCondition) {
				// Get the keys from the condition
				const noMatchConditionKeys = Object.keys(noMatchCondition);
				// For each key
				for (let key of noMatchConditionKeys) {
					// Check that the user[key] matches the condition
					if (user[key] === noMatchCondition[key]) {
						return false;
					}
				}
			}
			return true;
		},
		/**
		 * Determine the nature, or lack of, relationship between a user and a client
		 * @param {Object} user auth user object containing assigned clients
		 * @param {String} clientSlug the client's slug being accessed
		 * @returns false if the user isn't assigned to the client otherwise the nature of the relationship e.g. 'mainContact' or 'user'
		 */
		getUserClientRelationship(user, clientSlug) {
			// Get the assigned Clients from the user object
			const { assignedClients } = user;

			if (!assignedClients[clientSlug]) return false;

			return assignedClients[clientSlug];
		},
		/**
		 * Determine if the user is the client's main contact
		 * @param {Object} user auth user object containing assigned clients
		 * @param {String} clientSlug the client's slug being accessed
		 * @returns true if the user is the main contact of the client
		 */
		isUserMainContact(user, clientSlug) {
			if (this.getUserClientRelationship(user, clientSlug) === 'mainContact') return true;
			return false;
		},
	},
};

export default abilitiesMixin;
