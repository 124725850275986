import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import store from './store';
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import VueNextSelect from 'vue-next-select';
import 'vue-next-select/dist/index.css';
import authorizationMixins from './mixins/authorizationMixins';
import slugify from './mixins/slugify';
import numberWithCommas from "./mixins/numberWithCommas";
import useValidate from '@vuelidate/core';
//import ColorTracker from "canvas-color-tracker"

// VUE 3 NOT YET SUPPORTED WITH FONT AWESOME VUE COMPONENT
/* import the fontawesome core */
// import { library } from "@fortawesome/fontawesome-svg-core"

// /* import specific icons */
// import { faPenToSquare } from "@fortawesome/pro-duotone-svg-icons"

// /* import font awesome icon component */
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

/* add icons to the library */
// library.add(faPenToSquare);

const app = createApp(App);

// // Uncomment this to disable vue warnings
// app.config.errorHandler = () => null;
// app.config.warnHandler = () => null;

app.use(store);

app.mixin(authorizationMixins);
app.mixin(slugify);
app.mixin(numberWithCommas);

app.use(router, axios);
app.use(useValidate());
//app.use(ColorTracker())
/* add font awesome icon component */
// app.component("FontAwesomeIcon", FontAwesomeIcon)

app.component('Datepicker', Datepicker);
app.component('VueNextSelect', VueNextSelect);

app.mount('#app');
