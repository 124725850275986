export default {
	/**
	 * Realistically, we should avoid using this getter as property names may change e.g.
	 * getUserData.x could become getUserData.y thus requiring an update of all getUserData dot notation references
	 *
	 * It's here just in case!
	 * @returns The entirety of the user's data
	 */
	getUserData: ({ userData }) => {
		return userData;
	},
	getUserId: ({ userData }) => {
		return userData.id;
	},
	/**
	 *
	 * @returns The current selected (not the logged in) user's username
	 */
	getUserUsername: ({ userData }) => {
		return userData.username;
	},
	/**
	 *
	 * @returns The current selected (not the logged in) user's full name
	 */
	getUserFullName: ({ userData }) => {
		return `${userData.firstName} ${userData.lastName}`;
	},
	/**
	 *
	 * @returns The current selected (not the logged in) user's first name
	 */
	getUserFirstName: ({ userData }) => {
		return userData.firstName;
	},
	/**
	 *
	 * @returns The current selected (not the logged in) user's last name
	 */
	getUserLastName: ({ userData }) => {
		return userData.lastName;
	},
	/**
	 *
	 * @returns The current selected (not the logged in) user's avatar url
	 */
	getUserAvatarUrl: ({ userData }) => {
		return userData.avatarUrl;
	},
	/**
	 *
	 * @returns The current selected (not the logged in) user's email address
	 */
	getUserEmail: ({ userData }) => {
		return userData.email;
	},
	/**
	 *
	 * @returns The current selected (not the logged in) user's global role id
	 */
	getUserGlobalRoleId: ({ userData }) => {
		return userData.globalRoleId;
	},
	/**
	 *
	 * @returns The current selected (not the logged in) user's creation date
	 */
	getUserCreationDate: ({ userData }) => {
		return userData.createdAt;
	},
	/**
	 *
	 * @returns The current selected (not the logged in) user's last updated date
	 */
	getUserUpdatedDate: ({ userData }) => {
		return userData.updatedAt;
	},
	/**
	 * Shouldn't actually return anything as user's passwords are sanitised in the API
	 * @returns The current selected (not the logged in) user's password
	 */
	getUserPassword: ({ userData }) => {
		return userData.password;
	},
	/**
	 *
	 * @returns The current selected (not the logged in) user's assigned client(s);
	 */
	getUserClients: ({ userData }) => {
		return userData.clients;
	},
};
