export default {
	toggleSidebar(context) {
		context.commit("toggleSidebar")
	},
	openSidebar(context) {
		context.commit("openSidebar")
	},
	closeSidebar(context) {
		context.commit("closeSidebar")
	},
	setSidebar(context) {
		context.commit("setSidebar")
	},
}
