import userGetters from './getters.user';
import userMutations from './mutations.user';
import userActions from './actions.user';

export default {
	namespaced: true,
	state() {
		return {
			userData: {},
		};
	},
	mutations: userMutations,
	actions: userActions,
	getters: userGetters,
};
