export default {
	getRoleName(state) {
		return state.roleData?.name;
	},
	getRoleSlug(state) {
		return state.roleData?.slug;
	},
	getRolePermissions(state) {
		return state.roleData?.permissions || [];
	},
	getIsGlobalRole(state) {
		return state.roleData?.isGlobalRole;
	},
	getRoleData(state) {
		return state.roleData;
	},
};
